<template>
  <v-card class="px-4 pb-4 pt-5" width="400">
    <v-img :src="logoImg" contain alt="DroneShield" />
    <v-card-text>
      <v-layout row>
        <v-flex xs12>
          <v-container text-xs-center>
            Instructions to reset your password have been emailed to you. Please
            check your email and follow instructions.
          </v-container>
        </v-flex>
      </v-layout>
      <v-layout row class="mt-4">
        <v-flex xs12 class="text-xs-center">
          <v-btn outline large color="primary" @click="onClickLogin"
            >Log in</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PasswordResetCard',
  methods: {
    onClickLogin() {
      this.$emit('clickLogin')
    }
  },
  computed: {
    ...mapGetters('system', ['assetsPath']),
    logoImg() {
      return require(`@/assets/${this.assetsPath}logo.svg`)
    }
  }
}
</script>
